import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Navigation from "../navigation/navigation";
import "./header.scss"

export default () => {

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header className="header">

      <div className="left">
        <h1 className="logo">{data.site.siteMetadata.title}</h1>
      </div>

      <div className="right">
        <Navigation />
      </div>

    </header>
  )
}
