import React from "react";
import "./footer.scss"
import Twitter from "../../assets/svg/icons-feather/twitter.svg"

const Footer = () => {

  return (
    <div className="footer">
      <ul className="social">
        <li><a href="https://twitter.com/askibinski" target="_blank" rel="noopener noreferrer"><Twitter/><span className="hidden">Twitter</span></a></li>
      </ul>
    </div>
  );
};

export default Footer;
