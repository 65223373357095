import React from "react"
import {Link} from "gatsby"
import "./button.scss"

// A button which can be a button element with a callback or a link element if a link is provided.

export default (props) => {

  // Merging class names from parent components with default.
  let classNames = ['button'];
  if (props.className) {
    props.className.split(' ').forEach(function (name) {
      classNames.push(name);
    });
  }
  classNames = classNames.join(' ');

  // Sometimes we just want a fake button.
  if (props.callback === undefined && props.link === undefined) {
    return (
      <>
        <button className={classNames}>{props.children}</button>
      </>
    )
  }

  if (props.callback) {
    return (
      <>
        <button className={classNames} onClick={props.callback}>{props.children}</button>
      </>
    )
  }

  if (props.link) {
    return (
      <>
        <Link className={classNames} to={props.link}>{props.children}</Link>
      </>
    )
  }

}
